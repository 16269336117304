import React from 'react';
import './App.css';
import yourImage from './About.png';

function App() {

return (
  <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#0C0C0C'
    }}>
    <img src={yourImage} alt="Ryzen 9 7950X specs sheet" style={{
      maxHeight: '95%',
      objectFit: 'contain'
    }}/>
  </div>
);
}

export default App;
